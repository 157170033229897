import React from 'react'
import Navbar from "../components/Nav";
import "./contact.css"
import { Form, Col, Button } from 'react-bootstrap'
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'
import Wave2 from "../images/wave_2.png"
// Disable the auto CSS insertion
config.autoAddCss = false

export default function Contact() {
    return (
        <>
          <Navbar>

          </Navbar>
          <div className="contact-container">
            <div className="contact-info-box">
              <img src={Wave2} alt="" id="wave-contact"/>

              <div className="box-contact-page">
                <div className="info-text">
                  <h1 className="person">Eduardo Gouveia</h1>
                  <p className="info">+32 488 52 02 60</p>
                  <p className="info">info@echorses.com</p>
                  <p className="info">Smeetshofweg 71, 3990 Peer, Belgium</p>
                </div>

              <div className="contact-page-form">
                <Form method="post" action="https://formspree.io/f/mqkwzppj">

                  <Col className="contactrow">
                    <Form.Control placeholder="name" className="placeholder" />
                  </Col>

                  <Col className="contactrow">
                    <Form.Control placeholder="e-mail" name="_replyto" className="placeholder" />
                  </Col>

                  <Col className="contactrow">
                    <Form.Control placeholder="message" className="placeholder-message" />
                  </Col>

                  <Col className="contactrow">
                    <Button id="btn" type="submit" size="sm">send</Button>
                  </Col>

                </Form>
              </div>

              <div className="mapwithicons" id="map-contact-page">

                <div className="map">
                  <a href="https://www.google.com/maps/place/Smeetshofweg+71,+3990+Peer,+Belgium/@51.1453934,5.4934579,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0d5ef67e7b3fd:0x4fb09e98ca3430f6!8m2!3d51.1453934!4d5.4956466">
                  <StaticGoogleMap size="320x170"  id="googlemap" className="img-fluid" apiKey="AIzaSyCEMRXdqU638OrNX7YNIWzx3cgQCK0h5Vk">
                  <Marker location="51.14556837732422, 5.4957431577514475" color="red" label="" />
                  </StaticGoogleMap>
                  </a>
                </div>

              <div className="social-media-footer">
                <a className="nav-icon2" alt="Instagram icon" href="https://www.instagram.com/ec.horses/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="fa-fw" />
                </a>
                <a className="nav-icon2" alt="Facebook icon" href="https://www.facebook.com/ec.horses17" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} className="fa-fw" />
                </a>
                <a className="nav-icon2" alt="Youtube icon" href="https://www.youtube.com/channel/UCSaoMvPuMr3gzUjh32qWGbg" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutubeSquare} className="fa-fw" />
                </a>
              </div>

              </div>
              </div>


            </div>
          </div>

        </>
    )
}

